var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-card',{staticClass:"white-bg",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-secondary"},on:{"click":_vm.goBack}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left"}}),_vm._v(" Regresar")],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveUser)}}},[_c('h3',[_c('font-awesome-icon',{attrs:{"icon":"user-circle"}}),_vm._v(" Datos generales")],1),_c('br'),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-md-right text-left",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Usuario")])]),_c('b-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Usuario","state":errors[0] ? false : null},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('b-form-invalid-feedback',{staticClass:"text-left"},[_vm._v("Ingresa el usuario")])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-md-right text-left",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Nombre")])]),_c('b-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Nombre","state":errors[0] ? false : null},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('b-form-invalid-feedback',{staticClass:"text-left"},[_vm._v("Ingresa el nombre")])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-md-right text-left",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Correo")])]),_c('b-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Correo","state":errors[0] ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',{staticClass:"text-left"},[_vm._v("Ingresa el correo")])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"my-4"},[_c('b-col',{attrs:{"offset-md":"4","cols":"12","md":"8","lg":"6"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","block":""}},[_c('font-awesome-icon',{attrs:{"icon":"save"}}),_vm._v(" Actualizar")],1)],1)],1)],1)]}}])}),_c('br'),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.changePassword)}}},[_c('h3',{staticClass:"mt-4"},[_c('font-awesome-icon',{attrs:{"icon":"lock"}}),_vm._v(" Cambiar contraseña")],1),_c('br'),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-md-right text-left",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Contraseña actual")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"8","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"false","type":"password","placeholder":"Contraseña actual","state":errors[0] ? false : null},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-form-invalid-feedback',{staticClass:"text-left"},[_vm._v("Ingresa la contraseña actual")])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-md-right text-left",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Nueva contraseña")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"8","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"autocomplete":"false","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Nueva contraseña","state":errors[0] ? false : null},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"border-top-right-radius":".25rem !important","border-bottom-right-radius":".25rem !important"},attrs:{"variant":errors[0] ? 'outline-danger' : 'outline-primary',"title":"Mostrar contraseña"},on:{"click":function($event){_vm.showPassword = true},"mouseout":function($event){_vm.showPassword = false}}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}})],1)],1),_c('b-form-invalid-feedback',{staticClass:"text-left"},[_vm._v("Ingresa la nueva contraseña")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-3 mb-4"},[_c('b-col',{attrs:{"offset-md":"4","cols":"12","md":"8","lg":"6"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","block":""}},[_c('font-awesome-icon',{attrs:{"icon":"exchange-alt"}}),_vm._v(" Cambiar contraseña")],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }